import { createStore } from 'vuex'

export default createStore({
    state: {
        tagsList: [],
        collapse: false,
        myName: "张某",
        surname: "汤姆"
    },
    getters: {

    },
    mutations: {
        setVuexState(state, data) {
            if (Array.isArray(data)) {
                for (let index = 0; index < data.length; index++) {
                    state[data[index]["key"]] = data[index]["value"]

                }
            }
            else if (typeof data === 'object') {
                for (const key in data) {
                    if (Object.hasOwnProperty.call(state, key)) {
                        state[key] = data[key];
                    }
                    //state缺乏属性提示
                    else {
                        console.warn('vueX的state缺乏该属性：' + key)
                    }
                }
            }
        },
        updateMyName(state, data) {
            state.myName = data
        },
        delTagsItem(state, data) {
            state
                .tagsList
                .splice(data.index, 1);
        },
        setTagsItem(state, data) {
            state
                .tagsList
                .push(data)
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data
                            .$router
                            .push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data
                            .$router
                            .push(state.tagsList[i - 1].path);
                    } else {
                        data
                            .$router
                            .push("/");
                    }
                    state
                        .tagsList
                        .splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        hadndleCollapse(state, data) {
            state.collapse = data;
        }
    },
    actions: {},
    modules: {}
})